.scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.marginMedium {
  margin: 0px -40px 0px -40px;
  padding: 0px 40px 0px 40px;
}

.marginSmall {
  margin: 0px -24px 0px -24px;
  padding: 0px 24px 0px 24px;
}
