.container {
  height: 100%;
  width: 100%;
}

.element {
  width: 100%;
  position: relative;
  object-fit: cover;
  transition: all 800ms;
}

.animationSimpleIn {
  opacity: 1;
}

.animationSimpleOut {
  opacity: 0;
}

.animationSlideIn {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
}

.animationSlideOut {
  opacity: 0;
  transform: translate3d(0, 90px, 0);
}
